import React, {useState} from 'react'
import { Link } from 'react-router-dom'
const Header = () => {
  const [userLogged, setUserLogged] = useState(JSON.parse(localStorage.getItem("loggedInUser")));

  function logout_user(e) {
    e.preventDefault()
    localStorage.removeItem("loggedInUser")
    window.location.reload()
  }
  
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/">NutriGlucox</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/homepage">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/users">Users</Link>
        </li>

 
        
      </ul>

      {userLogged && (<><button className='btn btn-danger' onClick={(e) => logout_user(e)}>Logout - {userLogged['username']}</button></>)}
     
    </div>
  </div>
</nav>
    </div>
  )
}

export default Header
