import React from 'react'
let api__url = "http://recipes-app-backend-kenz.onrender.com/"
const Values = () => {

  return (
    <div>
      
    </div>
  )
}

export {Values, api__url}
